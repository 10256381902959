//Chakra UI
import {
  Alert,
  AlertIcon,
  Box,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  Stack,
  Text,
  Textarea,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react'
// React
import React, { useRef, useState } from 'react'
import { ValidationError, useForm } from '@formspree/react';

import { Helmet } from "react-helmet"
import Layout from '../layout'
import PageTransition from 'gatsby-plugin-page-transitions'
import Svg from '../images/statys-background-animation-large.svg'
import emailjs from '@emailjs/browser'

const Contact = ({ data }) => {

  var toast = useToast()

  const [state, handleSubmit] = useForm("xyyazbop");

    if (state.succeeded) {
    toast({
      status: 'success',
      title: "Message was sent!"
     })
    }
  
  const [formValues, setFormValues] = useState({
    fullName: "",
    email: "",
    phone: "",
    message: ""
  })

     const handleChange = (input) => (e)  => {
     const value = e.currentTarget.value;
     
     setFormValues(prevState => ({
       ...prevState,
      [input]: value
    }));
     
   };

  const isMobile = useBreakpointValue({ base: true, xl: false })

  return (
    <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>MDCONNECT DMCC</title>
        </Helmet>
      <PageTransition>
      <Container maxW="480px" py="140px" minHeight="100vh" display="flex" alignItems="center">
        <Box minW="480px">
          
          {!isMobile && (<Image src={Svg} style={{position: `absolute`, top:`-72px`, left: `0`, height: `60%`, width: `30%`}}></Image>)}
      
          <Heading fontSize="48px !important" m="0px !important" color="blue.500" pb="32px" fontWeight="300">Contact Us</Heading>
          <Text fontSize="sm" color="gray.600" pb="32px">Get in touch to know more about our solutions. <br></br> We look forward to hearing from you!</Text>

          <Box>
            <form onSubmit={handleSubmit} name="contact">
              <Stack spacing={4}>
                  <FormControl id="name" name="name" isRequired>
                    <FormLabel fontFamily="Gotham Medium" color="gray.700">Name</FormLabel>
                    <Input type="text" placeholder='Full Name' borderColor="gray.200" name="name" defaultValue={formValues.fullName} onChange={handleChange("fullName")}  />
                  </FormControl>
                  
                    <FormControl id="email" name="email" isRequired>
                        <FormLabel fontFamily="Gotham Medium" color="gray.700">Email</FormLabel>
                        <Input type="email" placeholder='Email address' name="email" defaultValue={formValues.email} onChange={handleChange("email")}  />
                  </FormControl>
                    <FormControl id="phone" name="phone" isRequired>
                        <FormLabel fontFamily="Gotham Medium" color="gray.700">Phone</FormLabel>
                        <Input type="tel" placeholder='Phone number' name="phone" defaultValue={formValues.phone} onChange={handleChange("phone")}  />
                      </FormControl>
                      <FormControl id="message" name="message" isRequired>
                        <FormLabel fontFamily="Gotham Medium" color="gray.700">Message</FormLabel>
                        <Textarea type="email" placeholder='Your Message' name="message" defaultValue={formValues.message} onChange={handleChange("message")} />
                    </FormControl>
                    <Stack>
                  <Stack direction={{ base: 'column', sm: 'row' }} align={'start'} justify={'space-between'}></Stack>
                  <input type="submit" value="Send" className='submit' />
                 
                </Stack>
              </Stack>
            </form>
          </Box>
          </Box>
        </Container>
        </PageTransition>
    </Layout>
  )
}

export default Contact
